export default class VendorsAPI {
  constructor($axios) {
    this.$axios = $axios;
    this.baseURL = process.env.BACKEND_URL;
  }

  getAll(params) {
    //console.log("params => ", params)

    return this.$axios.request({
      url: `${this.baseURL}/v1/vendors`,
      method: "POST",
      data: params,
      headers: {
        "Content-Type": "Application/json"
      }
    });
  }

  get(slug) {
    return this.$axios.request({
      url: `${this.baseURL}/v1/vendor/slug/${slug}`,
      method: "GET",
      headers: {
        "Content-Type": "Application/json"
      }
    });
  }

  getById(vendorId) {
    return this.$axios.request({
      url: `${this.baseURL}/v1/vendor/${vendorId}`,
      method: "GET",
      headers: {
        "Content-Type": "Application/json"
      }
    });
  }

  create(data) {
    return this.$axios.request({
      url: `${this.baseURL}/v1/vendor`,
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "Apllication/json"
      }
    });
  }

  update(data, vendorId) {
    return this.$axios.request({
      url: `${this.baseURL}/v1/vendor/${vendorId}`,
      method: "POST",
      data: data,
      headers: {
        //"Content-Type":"Application/json"
      }
    });
  }

  createImage(vendorId, imageUrl) {
    return this.$axios.request({
      url: `${this.baseURL}/v1/vendor/image/create`,
      method: "POST",
      data: {
        vendorId: vendorId,
        imageUrl: imageUrl
      },
      headers: {
        "Content-Type": "Application/json"
      }
    });
  }

  deleteImage(imageId) {
    return this.$axios.request({
      url: `${this.baseURL}/v1/vendor/image/${imageId}`,
      method: "DELETE",
      headers: {
        "Content-Type": "Application/json"
      }
    });
  }

  // ===== customers =====
  createCustomer(data) {
    // console.log(" create data customers ==> ", data);

    return this.$axios.request({
      url: `${this.baseURL}/v1/vendor/customer/`,
      method: "POST",
      headers: {
        "Content-Type": "Application/json"
      },
      data: data
    });
  }

  deleteVendorCustomer(customerId) {
    return this.$axios.request({
      url: `${this.baseURL}/v1/vendor/customer/${customerId}`,
      method: "DELETE",
      headers: {
        "Content-Type": "Application/json"
      }
    });
  }

  updateCustomer(customerId, data) {
    console.log(" update data customers ==> ", data);

    return this.$axios.request({
      url: `${this.baseURL}/v1/vendor/customer/${customerId}`,
      method: "PUT",
      headers: {
        "Content-Type": "Application/json"
      },
      data: data
    });
  }
  /*
	  filter : {
		vendor_id
	  }
  
	*/
  getAllCustomer(data) {
    return this.$axios.request({
      url: `${this.baseURL}/v1/vendor/customers`,
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "Application/json"
      }
    });
  }
}
