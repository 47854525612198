export default class CompanyAPI{
	constructor($axios) {
		this.$axios = $axios
    	this.baseURL = process.env.BACKEND_URL;
	}

	getAll(params) {
		return this.$axios.request({
			'url': `${this.baseURL}/v1/company`,
			'method': 'GET',
			'params': params,
			'headers': {
				'Content-Type': 'Application/json'
			}
		})
	}

	get(slug) {
		return this.$axios.request({
			'url': `${this.baseURL}/v1/company/${slug}`,
			'method': 'GET',
			'headers': {
				'Content-Type': 'Application/json'
			}
		})
	}
}