<template>

  <b-form  class="shipyard-filter" :class="{'is-login' : account != null}">

      <h2>{{ $t('shipyard_filter.heading') }}</h2>

      <div class="row search-by-city">
        <b-col>
          <div class="form-group">
            <b-form-input type="text" placeholder="Search by city" @keyup.native="searchCity" v-model="form.cityname" :value="cityFilter && cityFilter.name"/>
            <ul class="city-suggestion" v-if="cityResult">
              <li v-for="(city, index) in cityResult" @click="selectCity(index)">
                <span v-html="highlightText(city.name)"></span>
              </li>
            </ul>
          </div>
        </b-col>
        <b-col class="btn1">
          <div class="form-group">
            <b-button class="primary btn-cari" @click="filter"> {{ $t('shipyard_filter.search') }} </b-button>
          </div>
        </b-col>
      </div>

      <div class="row pos-relative">
        <div class="blocking" @click="openLoginPopup">
          <h3>Free Advance Search</h3>
          <a class="btn-primary medium" href="#">Login / Signup</a>
        </div>
        <div class="col-lg-6">
          <span class="label-filter">{{ $t('shipyard_filter.ship_detail') }}</span>
          <b-form-group>
            <b-form-select :options="type" v-model="form.type">

            </b-form-select>
          </b-form-group>

          <b-form-group>
            <b-form-select :options="material" v-model="form.material">

            </b-form-select>
          </b-form-group>
        </div>

        <div class="col-lg-6">
          <span class="label-filter">{{ $t('shipyard_filter.ship_size') }}</span>
          <div class="row">
            <div class="col-lg-6 pr-0">
              <div class="form-group">
                <b-form-input type="text" placeholder="Length" v-model="form.length" />
              </div>
            </div>
            <div class="col-lg-6 width-size not-login-no-display">
              <div class="form-group">
                <b-form-input type="text" placeholder="Width" v-model="form.width" />
              </div>
            </div>

            <div class="col-lg-6 pr-0 not-login-no-display">
              <div class="form-group">
                <b-form-input type="text" placeholder="Draft" v-model="form.draft" />
              </div>
            </div>

            <div class="col-lg-6 not-login-no-display">
              <div class="form-group">
                <b-form-input type="text" placeholder="DWT"  v-model="form.lwt" />
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6 not-login-no-display">
          <span class="label-filter">{{ $t('shipyard_filter.checkin') }}</span>
          <div class="row">
            <div class="col-lg-12">
              <b-form-group>
                <select class="form-control" v-model="form.month">
                  <option :key="i" v-for="(month, i) in months" :value="month.value" :disabled="month.value < $moment().format('M')">{{month.name}}</option>
                  <option value="">All Month</option>
                </select>
              </b-form-group>
            </div>

            <div class="col-lg-12 not-login-no-display">
              <b-form-group>
                <select class="form-control" v-model="form.week">
                  <option :key="i" v-for="(week, i) in [0, 1, 2, 3, 4]" :value="week" >{{ week ? `Week ${week}` : 'Week'}}</option>
                </select>
              </b-form-group>
            </div>


          </div>

        </div>

        <div class="col-lg-6 not-login-no-display">
          <span class="label-filter">{{ $t('shipyard_filter.facility') }}</span>
          <div class="row">

            <div class="col-lg-12">
              <b-form-group>
                <b-form-select :options="method" v-model="form.method">

                </b-form-select>
              </b-form-group>
            </div>

            <div class="col-lg-12 not-login-no-display">
              <b-form-group>
                <multiselect v-model="form.certificates" label="name"  :options="certificates" tag-placeholder="Iso type" placeholder="Iso Type" :multiple="true" :taggable="true" @tag="addCertificate" track-by="id"></multiselect>
              </b-form-group>
            </div>

          </div>

        </div>

      </div>

      <b-row class="not-login-no-display">

        <b-col lg="6" md="6" sm="12">
          <b-form-group>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="gridCheck">
              <label class="form-check-label font-roboto" style="padding:0px 0 0 2px; font-size:15px;" for="gridCheck">
                {{ $t('shipyard_filter.insurance') }}
              </label>
            </div>
          </b-form-group>
        </b-col>

        <b-col lg="6" md="6" sm="12" class="flex-dix">
          <div class="form-group">
            <b-button class="primary btn-cari btn2" @click="filter">{{ $t('shipyard_filter.search') }}</b-button>
          </div>
        </b-col>

      </b-row>

  </b-form>

</template>


<script>

import ShipyardsAPI from '~/api/shipyards.js'
import MiscAPI from '~/api/misc.js'
import Multiselect from 'vue-multiselect'

import Cookie from 'js-cookie'

  export default {
    components: { Multiselect },
    data () {
      return {
        form: {
          type: null,
          material : null,
          width : null,
          length : null,
          draft : null,
          lwt : null,
          certificates : null,
          method : null,
          schedule:null,
          insurance:null,
          sailing_area_id : null,
          cityname: null,
          cityslug: null,
          month: null,
          week: null
        },
        cityResult: null,
        type: [
          {value: null, text: 'Vessel Type'},
        ],
        method: [
          {value: null, text: 'Select Method'},
        ],
        material: [
          {value: null, text: 'Vessel Material'},
        ],

        selected_date: [
          {value: null, text:"Please Select date"}
        ],

        months: [
          {
            value: 0,
            name: 'Month'
          },
          {
            value: 1,
            name: 'Januari'
          },
          {
            value: 2,
            name: 'Februari'
          },
          {
            value: 3,
            name: 'Maret'
          },
          {
            value: 4,
            name: 'April'
          },
          {
            value: 5,
            name: 'Mei'
          },
          {
            value: 6,
            name: 'Juni'
          },
          {
            value: 7,
            name: 'July'
          },
          {
            value: 8,
            name: 'Agustus'
          },
          {
            value: 9,
            name: 'September'
          },
          {
            value: 10,
            name: 'Oktober'
          },
          {
            value: 11,
            name: 'November'
          },
          {
            value: 12,
            name: 'Desember'
          }
        ],

        show: true,

        selected: 'first',

        certificates: [
          { name: 'ISO 9001', id: 1 },
          { name: 'OHSAS 18001', id: 2 },
          { name: 'Environment 14001', id: 3 }
        ],
      }
    },
    methods: {
      filter() {
        let filters = {
          type: this.form.type,
          material: this.form.material,
          sailing_area_id: this.form.area,
          width: this.form.width,
          length: this.form.length,
          draft: this.form.draft,
          lwt: this.form.lwt,
          method: this.form.method,
          has_assurance: null,
          city: this.form.cityslug,
          citySelected: null,
          certificates: this.form.certificates,
          month: this.form.month,
          week: this.form.week
        }

        this.updateUrl(filters);
        this.$store.dispatch('setFilters', {city: this.citySelected})
      },

      highlightText(text) {
        let index = text.toLowerCase().indexOf(this.form.cityname);
        if (index >= 0) {
          return text.substring(0, index) + "<strong>" + text.substring(index, index + this.form.cityname.length) + "</strong>" + text.substring(index + this.form.cityname.length);
        } else {
          return text;
        }
      },

      updateUrl(filters = null) {
        let location = {
          path: 'galangan',
          query: {}
        };

        let city = 'all-cities';
        let method = 'all-methods';
        let type = 'all-types';
        let material = 'all-materials';

        if(filters && filters.city) city = filters.city;

        if(filters && filters.method) method = filters.method;

        if(filters && filters.type) type = filters.type;

        if(filters && filters.material) material = filters.material;

        if(filters && filters.sailing_area_id) location.sailing_area = filters.sailing_area_id;

        if(filters && filters.width) location.query.width = filters.width;

        if(filters && filters.length) location.query.length = filters.length;

        if(filters && filters.draft) location.query.draft = filters.draft;

        if(filters && filters.has_assurance) location.query.has_assurance = filters.has_assurance;

        if(filters && filters.certificates) location.query.certificates = filters.certificates.map(c => c.id).join(',');

        if(filters && filters.month) location.query.month = filters.month;

        if(filters && filters.week) location.query.week = filters.week;

        location.path = `/${location.path}/${city}/${method}/${type}/${material}`

        this.$emit('filterAction', location);
      },

      addCertificate (data) {
        const tag = {
          name: data,
          id: data.substring(0, 2) + Math.floor((Math.random() * 10000000))
        }
        this.certificates.push(tag)
        this.form.certificates.push(tag)
      },

      onClick() {
        // Close the menu and (by passing true) return focus to the toggle button
        this.$refs.ddown.hide(true)
      },
      openLoginPopup() {
  			this.$root.$emit('bv::show::modal','modalLogin')
  		},
  		closeLoginPopup() {
  			this.$root.$emit('bv::hide::modal','modalLogin')
  		},
      searchCity() {
        let MiscApi = new MiscAPI(this.$axios);

        if(this.form.cityname || this.form.cityname !== '') {
          MiscApi.searchCity(this.form.cityname).then(res => {
            this.cityResult = res.data.data;
          })
        } else {
          this.cityResult = null;
        }
      },
      selectCity(index) {
        this.form.cityname = this.cityResult[index].name;
        this.form.cityslug = this.cityResult[index].slug;
        this.citySelected = this.cityResult[index];

        this.cityResult = null;
      }
    },
    computed: {
      account() {
        let data = this.$store.state.account.account;

        if(data && data.type == 'user') data.detail = data.user;
        else if(data && data.type == 'shipyard') data.detail = data.shipyard;
        else if(data && data.type == 'vendor') data.detail = data.vendor;

        return data;
      },
      cityFilter () {
        let city = this.$store.state.shipyardFilter.filters.city;
        if(city) this.form.cityname = city.name;
        return city;
      }
    },
    created() {
      if(this.$route.query.certificates) {
        this.form.certificates = this.certificates.filter(c => {
          let cerParam = this.$route.query.certificates.split(',').map(d => parseInt(d));
          return cerParam.indexOf(c.id) > -1;
        })
      }
    },
    beforeMount() {
      this.form = {
        type: null,
        material : null,
        cityslug : null,
        method : null,
        width : this.$route.query.width || null,
        length : this.$route.query.length || null,
        draft : this.$route.query.draft || null,
        province : this.$route.query.province || null,
        sailing_area_id : this.$route.query.sailing_area_id || null,
        cityname: null,
        month: this.$route.query.month || this.months[0].value,
        week: this.$route.query.week || 0,
        certificates: this.form.certificates
      }

      if(this.$route.params.type && !this.$route.params.type.includes('all-')) this.form.type = this.$route.params.type;
      if(this.$route.params.material && !this.$route.params.material.includes('all-')) this.form.material = this.$route.params.material;
      if(this.$route.params.category && !this.$route.params.category.includes('all-')) this.form.method = this.$route.params.category;
      if(this.$route.params.city && !this.$route.params.city.includes('all-')) this.form.cityslug = this.$route.params.city;

      let miscApi = new MiscAPI(this.$axios);

      miscApi.getDockMethod().then(method => {
        let _method = method.data.map(d => {
          return {
            text: d.name,
            value: d.slug
          }
        });
        this.method = [...this.method, ..._method];
        miscApi.getDockMaterial().then(material => {
          let _material = material.data.map(m => {
            return {
              text: m.name,
              value: m.slug
            }
          });
          this.material = [...this.material, ..._material];
          miscApi.getShipType().then(type => {
            let _ship_type = type.data.map(t => {
              return {
                text: t.name,
                value: t.slug
              }
            });
            this.type = [...this.type, ..._ship_type];
          })
        })
      })
    }
  }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
  @import '~/assets/scss/_mixins.scss';
  @import './style.scss';
</style>
