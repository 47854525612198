export default class VendorCategoriesAPI{
    constructor($axios){
        this.$axios = $axios
    	this.baseURL = process.env.BACKEND_URL;
    }

    getAll(){
       
        return this.$axios.request({
			'url': `${this.baseURL}/v1/vendor_category`,
			'method': 'GET',
			'headers': {
				'Content-Type': 'Application/json'
			}
		})
    }

    getDetail(id){
        return this.$axios.request({
			'url': `${this.baseURL}/v1/vendor_category/${id}`,
			'method': 'GET',
			'headers': {
				'Content-Type': 'Application/json'
			}
		})
    }
}