<template>

  <b-form class="vendor-filter">


      <b-row>
        <b-col lg="3" md="12">
          <h2>Cari Vendors</h2>
        </b-col>
        <b-col lg="2" md="12">
          <b-form-group>
            <b-form-select :options="bunker_provision" v-model="form.bunker_provision">

            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col lg="2" md="12">
          <b-form-group>
            <b-form-select :options="machinery" v-model="form.machinery">

            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col lg="2" md="12">
          <b-form-group>
            <b-form-select :options="marine_equipment" v-model="form.marine_equipment">

            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col lg="3" md="12">
          <b-button class="primary">Search</b-button>
        </b-col>
      </b-row>

  </b-form>


</template>

<script>
export default {
  data () {
    return {
      form: {
        bunker_provision: null,
        machinery : null,
        marine_equipment : null
      },
      bunker_provision: [
        { text: 'Bunker & Provision', value: null },
        'Fresh Water', 'Beans', 'Tomatoes', 'Corn'
      ],
      machinery: [
        { text: 'Machinery', value: null },
        'Pump', 'AC', 'Tomatoes', 'Corn'
      ],
      marine_equipment: [
        { text: 'Marine Equipment', value: null },
        'Carrots', 'Beans', 'Tomatoes', 'Corn'
      ],
      show: true,
      selected: 'first'
    }
  }
}
</script>

<style lang="scss">

  .vendor-filter {
    border-radius: 10px;
    background-color: #f4f5f7;
    padding: 15px;
    margin-bottom: 25px;
    .form-group {
      margin-bottom: 0px;
    }
    button.primary {
      width: 100%;
    }
    h2 {
      margin-top: 11px;
      color: #fa5b33;
      font-size: 20px;
      text-transform: uppercase;
    }
  }
</style>
