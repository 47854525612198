export default class BlogsAPI{
	constructor($axios) {
		this.$axios = $axios
		this.baseURL = process.env.BACKEND_URL;
	}

	getAll() {

		return this.$axios.request({
			'url': `${this.baseURL}/v1/blog`,
			'method': 'GET',
			'headers': {
				'Content-Type': 'Application/json'
			}
		})
	}

	getAllByCategories(categorySlug){
		return this.$axios.request({
			'url': `${this.baseURL}/v1/blog/category/${categorySlug}`,
			'method': 'GET',
			'headers': {
				'Content-Type': 'Application/json'
			}
		})
	}

	getAllCount(){
		return this.$axios.request({
			'url': `${this.baseURL}/v1/blog/allcount`,
			'method': 'GET',
			'headers': {
				'Content-Type': 'Application/json'
			}
		})
	}

	get(slug) {
		return this.$axios.request({
			'url': `${this.baseURL}/v1/blog/${slug}`,
			'method': 'GET',
			'headers': {
				'Content-Type': 'Application/json'
			}
		})
	}

	getSearchBlog(keyword,category) {

		let queryParams = ""

		if(category !== undefined) {
			queryParams += `?category=${category}`
		}

		return this.$axios.request({
			"url":`${this.baseURL}/v1/blog/search/${keyword}${queryParams}`,
			"methods":"GET",
			"headers":{
				"Content-Type":"Application/json"
			}
		})
	}
}