<template>
  <div class="pos-relative home">

    <a v-if="!account" href="/become-partner" class="daftarBaru mobile-only">
      <p>Daftarkan Perusahaan Anda &#8594;</p>
    </a>

    <div class="home-banner">

      <div class="container">

        <div class="row">

          <div class="welcome-block col-lg-5">
            <h1 class="animated fadeInDown delay-1s">
              {{ $t('home.slide_label') }}
            </h1>
            <p class="animated fadeInDown delay-1s"><span>{{ $t('home.slide_label_text') }}</span>
            </p>
            <div class="options animated fadeInDown delay-1s">
              <a href="/galangan" class="box opt-shipyard">{{ $t('home.explore_shipyards') }}</a>
              <a href="/vendor" class="box opt-vendor">{{ $t('home.explore_vendors') }}</a>
            </div>
          </div>

          <div class="col-lg-7">
            <GalanganFilter  class="animated fadeInDown delay-1s" @filterAction="filterAction"/>
          </div>

        </div>

      </div>

      <SlideAds />

      <!-- <div class="event-home">
        <a class="desktop-only" href="https://www.inamarine-exhibition.net/conference/">
          <img class="desktop-only" src="/images/Inamarine-800x800.png" alt="">
        </a>
        <a class="mobile-only" href="https://www.inamarine-exhibition.net/conference/">
          <img class="mobile-only" src="/images/Inamarine-2480-x-520.png" alt="">
        </a>

      </div> -->

    </div>

    <div class="how-it-works">
      <!-- <div class="row mobile-only">
        <div class="col-lg-12">
          <a href="https://www.docking.id/id/imatap-webinar">
            <img src="/images/IMATAP WEBINAR BY DOCKING.ID.png" alt="IMATAP Webinar" style="margin-bottom:20px;">
          </a>
        </div>
      </div> -->

      <!-- <div class="row desktop-only">
        <div class="col-lg-12">
          <a href="https://www.docking.id/id/imatap-webinar">
            <img src="/images/IMATAP WEBINAR DESKTOP.png" alt="IMATAP Webinar" style="margin-bottom:20px;">
          </a>
        </div>
      </div> -->

      <b-container>
        <span class="font-tuesday sign">All Welcome Aboard</span>
        <h2 class="heading-text"><span>{{ $t('home.easy_fast') }}</span></h2>
        <p class="subheading-text">
          {{ $t('home.subheading_easy') }}
        </p>
        <b-row>

          <b-col lg="4" md="12">
            <div class="box-step">
              <span class="number">1</span>
              <div class="box-img"><img src="/images/step1.svg"></div>
              <p>{{ $t('home.easy_1') }}</p>
            </div>
          </b-col>
          <b-col lg="4" md="12">
            <div class="box-step">
              <span class="number">2</span>
               <div class="box-img"><img src="/images/step2.svg"></div>
               <p>{{ $t('home.easy_2') }}</p>
            </div>
          </b-col>
          <b-col lg="4" md="12">
            <div class="box-step">
              <span class="number">3</span>
               <div class="box-img"><img src="/images/step3.svg"></div>
               <p>{{ $t('home.easy_3') }}</p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <div class="benefits">
      <b-container>
        <h2 class="heading-text f-white"><span>{{ $t('home.benefit_all') }}</span></h2>
          <p class="subheading-text">
            {{ $t('home.subheading_benefit') }}
          </p>
          <b-row>
            <b-col class="pdl-0 pdr-0" lg="6" md="12">
              <img src="/images/shipyard-occupancy.png">
            </b-col>
            <b-col lg="6" md="12">
              <h3>{{ $t('home.benefits_vessel') }}</h3>
              <p>
                {{ $t('home.benefits_vessel_subheading') }}.
              </p>
                <ul>
                  <li><span class="checklist"></span> <p>{{ $t('home.benefits_vessel_1') }}</p></li>
                  <li><span class="checklist"></span> <p>{{ $t('home.benefits_vessel_2') }}</p></li>
                  <li><span class="checklist"></span> <p>{{ $t('home.benefits_vessel_3') }}</p></li>
                  <li><span class="checklist"></span> <p>{{ $t('home.benefits_vessel_4') }}</p></li>
                  <li><span class="checklist"></span> <p>{{ $t('home.benefits_vessel_5') }}</p></li>
                </ul>
                <!-- <button class="call-to-action primary btn primary btn-secondary">Pelajari lebih lanjut</button> -->
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="6" md="12">
              <h3>{{ $t('home.benefits_shipyard') }}</h3>
              <p>
                {{ $t('home.benefits_shipyard_subheading') }}.
              </p>
                <ul>
                  <li><span class="checklist"></span> <p>{{ $t('home.benefits_shipyard_2') }}</p></li>
                  <li><span class="checklist"></span> <p>{{ $t('home.benefits_shipyard_3') }}</p></li>
                  <li><span class="checklist"></span> <p>{{ $t('home.benefits_shipyard_4') }}</p></li>
                </ul>
                <!-- <button class="call-to-action primary btn primary btn-secondary">Pelajari lebih lanjut</button> -->
            </b-col>
            <b-col lg="6" md="12" class="pdl-0 pdr-0">
              <img style="width:66%;" src="/images/online-presence.png">
            </b-col>
          </b-row>
      </b-container>
    </div>

    <div class="home-partners">
      <b-container>
        <b-row>
          <b-col lg="6" md="6">
            <a href="https://www.docking.id/id/galangan/profile/pt-caputra-mitra-sejati">
              <div class="inner-img">
                <img src="/images/partners/caputra_mitra_sejati_logo.png" />
              </div>
            </a>
          </b-col>

          <b-col lg="6" md="6">
            <a href="https://www.docking.id/id/galangan/profile/pt-yasa-wahana-tirta-samudera?page=1">
              <div class="inner-img">
                <img src="/images/partners/samudera.png" />
              </div>
            </a>
          </b-col>
          <!-- <b-col lg="3" md="6">
            <a href="/galangan/profile/bayu-bahari-sentosa">
              <div class="inner-img">
                <img style="width:90%; margin:auto; display:block;" src="/images/partners/bayu_bahari_sentosa.png" />
              </div>
            </a>
          </b-col>
          <b-col lg="3" md="6">
            <a href="/galangan/profile/mariana-bahagia-shipyard">
              <div class="inner-img">
                <img style="width:90%; margin:auto; display:block;" src="/images/partners/marina_bahagia.png" />
              </div>
            </a>
          </b-col> -->

        </b-row>
      </b-container>
      <h4>{{ $t('home.our_partners') }}</h4>
    </div>

    <div class="home-vessel-needs">
      <h2 class="f-white"><span></span></h2>
      <b-container>
        <!-- <VendorFilter /> -->
        <ul class="vendor-category">
          <li>{{ $t('home.needs') }}</li>
          <li v-for="(vendorCategory,i) in vendorCategories" :key="i"><a :href="'vendor/all-cities/'+vendorCategory.slug">{{ vendorCategory.name }}</a></li>

        </ul>
      </b-container>

      <b-container>
        <b-row>
          <b-col lg="9" md="12">
            <b-row>


              <b-col lg="3" md="2" v-for="(vendor,i) in vendors" :key="i" v-if="i < 8"   >
                <a :href="'vendor/profile/'+vendor.slug">
                  <div class="inner-img">
                    <img :src="vendor.logo || defaultLogo" />
                    <div class="display-box">
  										<div class="text">
  											<p class="vendor-name">{{ vendor.name }}</p>
  											<span class="category display-inline mbtm-10">{{ vendor.vendorServices[0].service.name }}</span>

  											<b-badge variant="dark">{{ vendor.vendorCities[0].city !== null ? vendor.vendorCities[0].city.name : "" }}</b-badge>
  										</div>
  									</div>
                  </div>
                 </a>
              </b-col>


              <b-col lg="12" class="mtop-30 space">
              </b-col>

            </b-row>
          </b-col>
          <b-col lg="3" md="12">
            <div class="explore-box">
              <p>{{ $t('home.explore_box') }}</p>
              <a href="/vendor" class="btn-primary">
                <h4>Explore</h4>
              </a>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <h4>Premium Marine Vendors</h4>
    </div>

    <!-- <div class="home-become-partners">
      <b-container>
        <b-row>
          <h3 class="f-white">Want to GROW your shipyard business with us? <br>
              Create your FREE business profile now!
          </h3>
          <a href="/become-partner">
            BECOME A PARTNER
          </a>
        </b-row>
      </b-container>
    </div> -->


    <!-- <div class="blog-highlight">
        <h2 class="heading-text f-white"><span>Artikel unggulan </span></h2>
        <p class="subheading-text">
          Resources you need to grow your shipyard.
        </p>
        <b-container>
          <b-row>
            <b-col lg="4" md="12" v-for="(blog, i) in blogs" :key="i">
              <div class="inner-blog">
                <div class="thumb-img">
                  <img :src="blog.photo" alt="" />
                </div>
                <span class="tag">Community news</span>
                <p class="title">{{ blog.title }}</p>
                <span class="dates">{{ blog.createdAt | moment("DD MMMM YYYY") }}</span>
              </div>
            </b-col>
          </b-row>
          <a href="#" class="links">Lihat semua artikel</a>
        </b-container>
    </div> -->

    <!-- <div class="end-section">
      <b-container>
        <b-row>
          <h3>Tunggu apa lagi? Daftar sekarang dan bergabung <br/> komunitas docking Indonesia terbesar.</h3>
          <a href="/become-partner" class="btn-primary">
            Become Partner
          </a>
        </b-row>
      </b-container>
    </div> -->

  </div>
</template>

<script>
import GalanganFilter from '~/components/shipyard-filter'
import VendorFilter from '~/components/vendor-filter'
import BlogsAPI from '~/api/blogs.js'
import CompanyAPI from '~/api/company.js'
import VendorsAPI from '~/api/vendors.js'
import VendorCategoriesAPI from "~/api/vendor_categories.js"
import SlideAds from '~/components/slide-ads'

export default {
  layout :'default',
  components:{
    GalanganFilter,
    VendorFilter,
    SlideAds
  },
  data () {
    return {
      blogs: [],
      companies: [],
      vendors: [],
      vendorCategories:[],
      defaultLogo: '/images/vendors/dummy-vendor.png',
    }
  },
  methods: {
    filterAction(location) {
      this.$router.push({ path: location.path, query: location.query })
    }
  },
  beforeMount(){
    let blogsApi = new BlogsAPI(this.$axios);
    let CompanyApi = new CompanyAPI(this.$axios);
    let VendorsApi = new VendorsAPI(this.$axios);
    let VendorCategoriesApi = new VendorCategoriesAPI(this.$axios)

    blogsApi.getAll().then(blogs => {
      this.blogs = blogs.data;
    });

    CompanyApi.getAll({
      highlighted: 1,
      limit: 5
    }).then(company => {
      this.companies = company.data;
    })


    VendorsApi.getAll({
      highlighted: 1,
      limit: 8
    }).then(vendor => {
      // console.log(" => ",vendor.data.data)
      this.vendors = vendor.data.data;
    }).
    catch(err => {
      // console.log(err)
    })

    VendorCategoriesApi.getAll()
    .then( res => {
      this.vendorCategories = res.data
    })
    .catch(err => {
      // console.log("err vc => ",err)
    })
  },
  head() {
    return {
      title: 'DOCKING.ID | Indonesia Shipyards & Marine Vendors Platform',
      meta: [
        { name: 'description', hid: 'description', content: 'Docking.id : Indonesia Shipyard and Marine Vendor platform, where ship owners can request, compare and request docking slots for their ships easily with their fingertips.' },
        { name: 'keywords', hid: 'keywords',  content: 'Galangan Kapal Indonesia, Galangan Kapal Batam, Galangan Kapal Surabaya, Galangan Kapal Jakarta, Galangan Kapal Semarang,Galangan Kapal Sorong, Portal Galangan Kapal Indonesia, Vendor Kapal Batam, Vendor Kapal di Surabaya, Vendor Kapal di Jakarta, Vendor Kapal di Semarang, Perbaikan Kapal' },
        // Open Graph
        { hid: 'og:title', property: 'og:title', content: 'DOCKING.ID : Indonesia Shipyard & Marine Vendor Platform' },
        { hid: 'og:description', property: 'og:description', content: 'Docking.id : Indonesia Shipyard and Marine Vendor platform, where ship owners can request, compare and request docking slots for their ships easily with their fingertips.' },
        { hid: 'og:type', property: 'og:type', content: 'website' },
        { hid: 'og:url', property: 'og:url', content: 'https://www.docking.id' },
        { hid: 'og:image', property: 'og:image', content: 'https://www.docking.id/images/OG_IMAGE_DOCKING_ID.png' },
        // Twitter Card
        { hid: 'twitter:card', property: 'twitter:card', content: 'DOCKING.ID' },
        { hid: 'twitter:site', property: 'twitter:site', content: 'https://www.docking.id' },
        { hid: 'twitter:title', property: 'twitter:title', content: 'DOCKING.ID : Indonesia Shipyard & Marine Vendor Platform'},
        { hid: 'twitter:description', property: 'twitter:description', content: 'Docking.id : Indonesia Shipyard and Marine Vendor platform, where ship owners can request, compare and request docking slots for their ships easily with their fingertips.'},
        { hid: 'twitter:image', property: 'twitter:image', content: 'https://www.docking.id/images/OG_IMAGE_DOCKING_ID.png'  },
        { hid: 'twitter:image:alt', property: 'twitter:image:alt', content: 'https://www.docking.id/images/OG_IMAGE_DOCKING_ID.png' }
      ]
    }
  }
}
</script>

<style lang="scss">
	@import '~/assets/scss/_mixins.scss';
	@import './style.scss';
</style>
